/*=============================================
=         16. Testimonial slider Css           =
=============================================*/
.testimonial-slider {
    &.extra_m_lrtp {
        margin-left: -40px;
        margin-right: -40px;
        margin-top: -80px;
        margin-bottom: -80px;
        @media #{$laptop-device}{
            margin-left: -20px;
            margin-right: -20px;
        }
        @media #{$tablet-device}{
            margin-left: 0px;
            margin-right: 0px;
        }
        @media #{$large-mobile}{
            margin-left: -10px;
            margin-right: -10px;
        }
    }
    &__container-one {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 80px;
        padding-bottom: 80px;
        @media #{$laptop-device}{
            padding-left: 20px;
            padding-right: 20px;
        }
        @media #{$tablet-device}{
            padding-left: 0px;
            padding-right: 0px;
            
            padding-top: 100px;
            padding-bottom: 100px;
        }
        @media #{$large-mobile}{
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 100px;
        }
    }
}
.testimonial-slider__container-one {
    cursor: w-resize;
    & .swiper-slide {
        &.swiper-slide-prev {
            opacity: 0.43;
        }
        &.swiper-slide-next {
            opacity: 0.43;
        }
        &.swiper-slide-active {
            transform: scale(1.22, 1.22);
            
            
            & .testimonial-slider--box {
                padding: 40px 20px;
                box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
                
            }
            
            @media #{$large-mobile}{
                transform: scale(1, 1.08);
            }
            
        }
        & .testimonial-slider--box {
            padding: 34px;
            text-align: center;
            box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
            font-size: 13px;
            margin: 0px 0;
            & .reting-star {
                font-size: 15px;
                margin-bottom: 5px;
                color: $theme-color--two;
                & a {
                    margin: 0 2px;
                }
            }
            & .testimonial-slider__text {
                font-weight: 300;
                line-height: 1.8;
                & .start-block {
                    font-size: 30px;
                    display: inline-block;
                    height: 30px;
                    bottom: -12px;
                    line-height: 30px;
                    position: relative;
                }
                & .end-block {
                    font-size: 30px;
                    display: inline-block;
                    height: auto;
                    top: 20px;
                    line-height: 0.3;
                    position: relative;
                }
            }
            & .testimonial-slider__author {
                & .name {
                    font-weight: 500;
                    color: $theme-color--default;
                    margin-top: 10px;
                }
                
            }
        }
    }
}
.testimonial-slider__container-two {
    .testimonial-slider--box {
        max-width: 420px;
        & .testimonial-slider__text {
            font-weight: 300;
            line-height: 1.8;
            padding-left: 20px;
            & .start-block {
                font-size: 30px;
                display: inline-block;
                height: 30px;
                bottom: -8px;
                line-height: 30px;
                position: relative;
                margin-left: -20px;
            }
            & .end-block {
                font-size: 30px;
                display: inline-block;
                height: auto;
                top: 20px;
                line-height: 0.3;
                position: relative;
            }
        }
        & .testimonial-slider__author {
            margin-top: 20px;
            font-size: 15px;
            padding-left: 20px;
            & h6 {  
                color: $theme-color--default;
            }
        }
    }
    & .swiper-nav-button {
        background-image: none;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: none;
        width: 13px;
        height: 18px;
        bottom: 0;
        display: inline-block;
        position: relative;
        line-height: 18px;
        left: 0;
        margin-top: 20px;
        opacity: 0.6;
        &:hover {
            opacity: 1;
        }
    }
}







.testimonials-vertical-wrap {
    height: 480px;
    max-width: 500px;
    margin: auto;
    @media #{$small-mobile}{
        height: 780px;
    }
    .vertical-slider__container { 
        height: 100%;
    }
    .testimonial-slider-box {
        display: flex;
        align-items: center;
        padding: 30px;
        background:  #F7F7F7;
        transition: all 0.3s ease-in-out;
        @media #{$small-mobile}{
            flex-direction: column;
        }
        &:hover {
            box-shadow: 0px 0px 4px 0px rgba(39, 50, 114, 0.14);
        }
        & .testimonial-images {
            min-width: 94px;
            margin-right: 20px;
        }
    }
    .testimonial-slider-box {
        padding: 30px 25px 30px;
        .testimonial--box {
            & p {
                font-weight: 300;
            }
        }
        & .testimonial-slider__author {
            margin-top: 15px;
            text-align: right;
            font-size: 18px;
            & h6 {
                color: $theme-color--default;
            }
        }
    }

    .swiper-pagination-bullets {
        float: right;
        top: 50%;
        position: absolute;
        right: 40px;
        transform: translateY(-50%);
        & .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            display: block;
            border-radius: 100%;
            background: #000;
            margin: 10px;
            &.swiper-pagination-bullet-active {
                width: 10px;
                height: 10px;
            }
        }
        @media #{$tablet-device,$desktop-device}{
            right: 0px;
        }
        @media #{$large-mobile}{
            display: none;
        }
    }

    
}


.testimonial-slider__container-three {
    .testimonial-slider__single {
        display: flex;
        @media #{$small-mobile}{
            flex-direction: column;
        }
    }
    .testimonial-slider--box {
        & .testimonial-slider__text {
            font-weight: 300;
            line-height: 1.8;
            padding-left: 20px;
            & .start-block {
                font-size: 30px;
                display: inline-block;
                height: 30px;
                bottom: -8px;
                line-height: 30px;
                position: relative;
                margin-left: -20px;
            }
            & .end-block {
                font-size: 30px;
                display: inline-block;
                height: auto;
                top: 20px;
                line-height: 0.3;
                position: relative;
            }
        }
        & .testimonial-slider__author {
            margin-top: 20px;
            padding-left: 20px;
            & h6 {  
                color: $theme-color--default;
                font-size: 15px;
                margin-bottom: 3px;
            }
            & .company-name {
                font-size: 13px;
                color: #6b6b6b;
                font-weight: 500;
            }
        }
    }
    
    .testimonial-box-bg {
        position: relative;
        min-width: 150px;
        margin-right: 50px;
        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            height: 88%;
            width: 100%;
            background: #ddd;
            @media #{$small-mobile}{
                position: inherit;
            }
        }
        &.testimonial-three-images {
            padding: 12px 12px 0;
            .img-box {
                z-index: 1;
                position: relative;
            }
            @media #{$small-mobile}{
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }
    
    & .swiper-nav-button {
        background-image: none;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: none;
        width: 13px;
        height: 18px;
        bottom: 0;
        display: inline-block;
        position: relative;
        line-height: 18px;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 60px;
        margin-right: 5px;
        opacity: 0.6;
        &:hover {
            opacity: 1;
        }
    }

    
    
}
.section-title-border {
    position: relative;
    margin-right: 30px;
    &::after {
        height: 95%;
        width: 1px;
        background: $theme-color--default;
        right: 0;
        top: 50%;
        content: '';
        position: absolute;
        transform: translateY(-50%);
    }
    @media #{$tablet-device}{
        margin-right: 0px;
        &::after {
            display: none;
        }
    }
    @media #{$large-mobile}{
        margin-right: 0px;
        &::after {
            display: none;
        }
    }
}
