/*=============================================
=            20. Contact Us Css            =
=============================================*/
.contact-form {
    &__one {
        padding: 50px;
        background: #172541;
        & .contact-inner {
            margin-bottom: 30px;
            & input , 
            & textarea {
                width: 100%;
                background: transparent;
                color: #ffffff;
                border: 1px solid #585a5e;
                padding: 6px 10px;
                font-size: 15px;
                font-weight: 300;
            }
            & textarea  {
                width: 100%;
                height: 190px;
            }
        }
        @media #{$large-mobile}{
            padding: 20px;
        }
        & .personal-contact-btn {
            font-size: 18px;
            border: none;
        }
    }
    &__two {
        padding: 0 50px;
        & .contact-inner {
            margin-bottom: 30px;
            & input , 
            & textarea {
                width: 100%;
                background: transparent;
                color: $theme-color--default;
                border: 1px solid #e5e5e5;
                padding: 6px 10px;
                font-size: 15px;
                font-weight: 300;
            }
            & textarea  {
                width: 100%;
                height: 190px;
            }
        }
        @media #{$large-mobile}{
            padding: 0px;
        }
        
        & .personal-contact-btn {
            font-size: 18px;
            border: none;
        }
    }
    &__three {
        padding-right: 20px;
        & .contact-inner {
            margin-bottom: 30px;
            & input , 
            & textarea {
                width: 100%;
                background: transparent;
                color: $theme-color--default;
                border: 1px solid #e5e5e5;
                padding: 6px 10px;
                font-size: 15px;
                font-weight: 300;
            }
            & textarea  {
                width: 100%;
                height: 190px;
            }
        }
        @media #{$large-mobile}{
            padding: 0px;
        }
        
        & .personal-contact-btn {
            font-size: 18px;
            border: none;
        }
    }
}

.single-contact-info-wrap {
    & .icon-box {
        height: 70px;
        min-width: 70px;
        background: 
        #273272;
        margin-right: 25px;
        line-height: 70px;
        text-align: center;
        border-radius: 5000px;
        box-shadow: 0px 0px 29px 0px rgba(39, 50, 114, 0.31); 
    }
}
