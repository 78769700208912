
/*=========================================
=               06. Footer Css            =
===========================================*/
.footer-bg--overlay {
    position: relative;
    &::before {
        background-color: #1C2945;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0.98;
    }
}

.coustom-row-in-footer {
    display: flex;
    @media #{$large-mobile}{
        flex-direction: column;
    }  
}

.footer-widget-coustom-col {
    flex-basis: 50%;
    padding-left: 45px;
    @media #{$desktop-device}{
        padding-left: 0px;
    }  
    @media #{$tablet-device}{
        padding-left: 15px;
    }  
    @media #{$large-mobile}{
        padding-left: 0px;
    }  
    &:nth-child(2) {
        padding-left: 68px;
        @media #{$desktop-device}{
            padding-left: 15px;
        }  
        @media #{$tablet-device}{
            padding-left: 15px;
        }  
        @media #{$large-mobile}{
            padding-left: 0px;
        } 
    }
}

.footer-widget-dic {
    & p {
        color: $white;
        &.footer-dec {
            max-width: 334px;
        }
    }
    & h5 {
        font-weight: 600;
        color: $white;
    }
}
.newsletter-footer-wrap {
    & .widget-newsletter-form {
        display: flex;
        max-width: 342px;
        position: relative;
        & input:not([type="submit"]) {
            background: #505A6D;
            height: 45px;
            padding: 15px 130px 15px 15px ;
            border: 1px solid #505A6D;
            width: 100%;
            color: $white;
        }
        & .subscribe-button {
            position: absolute;
            right: 0;
            & .subscribe-btn {
                background: #C3C4C5;
                height: 45px;
                padding: 0 18px;
                border: none;
                color: $theme-color--default;
                &:hover {
                    color: $white;
                    background: $theme-color--two;
                }
            }
        }
        input:focus::-webkit-input-placeholder { color:#fff; }
        input:focus:-moz-placeholder { color:#fff; } /* FF 4-18 */
        input:focus::-moz-placeholder { color:#fff;} /* FF 19+ */
        input:focus:-ms-input-placeholder { color:#fff; } /* IE 10+ */
        input, textarea{
            &::placeholder{
                color: #fff;
            }
        }
    }
}

.footer-widget-wrapper{
    margin-bottom: -35px;
}
.footer-widget{
    margin-bottom: 30px;
    &__title{
        margin-top: -3px;
        color: $theme-color--black;
    }

    &__item-list {
        width: 50%;
        float: left;
        li{
            margin-bottom: 10px;
            color: $white;
            & a {
                position: relative;
                padding-left: 20px;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: "\f101";
                    font-family: $font-awesome-pro;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &__list {
        & .single-footer-widger {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            & h5 {
                font-weight: 600;
                color: $white;
                margin-bottom: 8px;
            }
            & p {
                color: $white;
            }
        }
    }
}
.footer-widget__wrap {
    display: flex;
    justify-content: space-between;
    & .single-footer-widger {
        flex-basis: 33.333%;
        padding-left: 60px;
        
        &:first-child {
            padding-left: 30px;
        }
        & h5 {
            font-weight: 600;
            margin-bottom: 8px;
        }
        & p {
            font-weight: 300;
        }
        
        
        
        @media #{$desktop-device}{
            padding-left: 15px;
        }  
        @media #{$tablet-device}{
            padding-left: 40px;
            &:first-child {
                padding-left: 15px;
            }
            &:nth-child(2) {
                padding-left: 10px;
            }
        }  
        @media #{$large-mobile}{
            padding-left: 15px;
            padding-right: 15px;
            &:first-child {
                padding-left: 15px;
                padding-right: 15px;
            }
        } 
    }
    @media #{$large-mobile}{
        flex-direction: column;
    } 
}

.copyright-text{
    display: inline-block;
}

/*=====  End of footer ======*/
