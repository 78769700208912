
/*=======================================
=           19. Timeline Css           =
=========================================*/

.ht-timeline {
    &.style-01{
        &.mb-different {
            .item{
                position: relative; 
                padding: 0px 0 17px 65px;
                &:last-child {
                    padding-bottom: 0;
                }
                @media #{$small-mobile}{
                    padding: 0px 0 17px 35px;
                }
            }
        }
        .item{
            position: relative; 
            padding: 0px 0 35px 65px;
            &:last-child {
                padding-bottom: 0;
                &::before {
                    display: none;
                }
            }
            @media #{$small-mobile}{
                padding: 0px 0 35px 35px;
            }

            &::before {
                position: absolute;
                left: 30px;
                margin-left: -1px;
                height: 100%;
                width: 1px;
                content: '';
                background: $theme-color--default;
                @media #{$small-mobile}{
                    left: 5px;
                    margin-top: 2px;
                }
            }


        }
        .dots {
            .middle-dot {
                position: absolute;
                top: 0px;
                left: 30px;
                transform: translate(-50%, 0);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                z-index: 2;
                background: $theme-color--default;
                @media #{$small-mobile}{
                    left: 5px;
                }
            }
        }
        & .tm-timeline-list {
            .timeline-col {
                position: relative;
                display: inline-block;
                width: 100%;
                margin-top: -5px;
                .content-body {
                    & h5 {
                        font-size: 18px;
                        margin-bottom: 3px;
                    }
                    & p {
                        line-height: 1.3;
                        & span {
                            font-weight: 300;
                        }
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                }

            }
        }
    }
    &.style-02 {
        &.mb-different {
            .item{
                position: relative; 
                padding: 0px 0 17px 65px;
                &:last-child {
                    padding-bottom: 0;
                }
                @media #{$small-mobile}{
                    padding: 0px 0 17px 35px;
                }
            }
        }
        .item{
            position: relative; 
            padding: 0px 0 25px 65px;
            &:last-child {
                padding-bottom: 0;
                &::before {
                    display: none;
                }
            }
            @media #{$small-mobile}{
                padding: 0px 0 25px 35px;
            }

            &::before {
                position: absolute;
                left: 30px;
                margin-left: -1px;
                height: 100%;
                width: 1px;
                content: '';
                background: $theme-color--default;
                @media #{$small-mobile}{
                    left: 5px;
                    margin-top: 2px;
                }
            }


        }
        .dots {
            .middle-dot {
                position: absolute;
                top: 0px;
                left: 30px;
                transform: translate(-50%, 0);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                z-index: 2;
                background: $theme-color--default;
                @media #{$small-mobile}{
                    left: 5px;
                }
            }
        }
        & .tm-timeline-list {
            .timeline-col {
                position: relative;
                display: inline-block;
                width: 100%;
                margin-top: -5px;
                .content-body {
                    & h5 {
                        font-size: 18px;
                        margin-bottom: 3px;
                    }
                    & p {
                        line-height: 1.3;
                        & span {
                            font-weight: 300;
                        }
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                }

            }
        }
    }
}