/*============================
    CSS Index         
==============================

    01. Default Css
        - Breadvrumb 
        - Preloader   
        - Scroll top 
    02. Section Spacing Css
    03. Custom Animation Css
    04. container Css 
    05. Header Css 
        - language
        - Navigation Menu Css
        - Multilevel Submenu Css
        - Mega Menu Css
        - Mobile Menu Overlay Css
    06. Footer Css
    07. Sidebar Css
    08. Product style Css   
    09. Cart Page Css  
    10. Checkout Page Css 
    11. Services Css
    12. Services Css
    13. Counters Css 
    14. Listing Css
    15. Call To Action Css
    16. Testimonial slider Css
    17. Team Css 
    18. Projcet Css
    19. Timeline Css
    20. Contact Us Css
    21. Pricing  Css   
    22. Corporate Pages Css       
    23. Personal Pages Css 
    24. Creative Pages Css
    25. Architecture Pages Css    
    26. StartUp Pages Css
    27. Blog Pages Css   
    28. Projct Details Pages Css
    29. Preview Page Css 
    
/*=====  End of CSS Index  ======*/


@import 'variabls';
@import 'global/default';
@import 'global/spacing';
@import 'global/animations';
@import 'layout/container';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/sidebar';
@import 'layout/product';
@import 'layout/cart';
@import 'layout/checkout';

/*@import 'elements';*/


@import 'elements/service';
@import 'elements/button';
@import 'elements/counters';
@import 'elements/listing';
@import 'elements/call-to-action';
@import 'elements/testimonial';
@import 'elements/social_networks';
@import 'elements/team';
@import 'elements/project';
@import 'elements/timeline';
@import 'elements/contact-us';
@import 'elements/pricing';




@import 'page-section/corporate-page';
@import 'page-section/personal';
@import 'page-section/creative-pages';
@import 'page-section/architecture-page';
@import 'page-section/start-up';
@import 'page-section/blogs-pages';
@import 'page-section/project-details';
@import 'page-section/preview-page';

